<template>
  <v-container fluid class="d-flex flex-column flex-md-row w-100 h-screen pa-0">
    <v-row no-gutters class="w-100 h-100">
      <v-col
        class="bg-white h-100 flex-grow-1 d-flex justify-center align-center"
      >
        <span class="text-h4">Welcome to Ragnet AI</span>
      </v-col>
    </v-row>
    <v-row no-gutters class="w-100 h-100">
      <v-col class="h-100 flex-grow-1 d-flex justify-center align-center">
        <v-btn
          color="surface"
          prepend-icon="mdi-google"
          href="https://auth.ragnet-ai.com/auth/google/start?prompt=consent&access_type=offline"
        >
          Login with Google
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts" setup></script>
